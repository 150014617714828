import { Alert, Box, Paper, Typography } from '@mui/material';
import { useAsyncState } from 'app/hooks';
import { useAppDispatch, useAppSelector, verifyEmail } from 'app/store';
import { WaitButton, Link } from 'components';
import { navigate } from 'gatsby';

import { Logo } from 'images/Logo';
import { useEffect } from 'react';

export default function VerifiEmail() {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const [state, success, failure, waiting] = useAsyncState();
  useEffect(() => {
    if (auth.loaded) {
      // not authenticated, redirect to signin
      if (auth.userInfo === null) {
        navigate('/', { replace: true });
        return;
      }
    }
  }, [auth]);

  const handleVerify = () => {
    dispatch(verifyEmail()).unwrap().then(success, failure);
    waiting();
  };

  if (auth.userInfo === null) {
    return null;
  }

  return (
    <Box
      component="article"
      sx={{
        pt: 12,
        width: '100vw',
        height: '100vh',
        overflow: 'auto',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        bgcolor: 'background.default',
      }}>
      <Logo />

      <Paper sx={{ my: 4, px: 4, py: 6, width: { xs: '90%', sm: 500 } }}>
        <Box sx={{ textAlign: 'center', mb: 3 }}>
          <Typography variant="h5">EMAIL VERIFICATION</Typography>
        </Box>
        {state.status === 'fulfilled' && (
          <Alert sx={{ mb: 2 }} severity="success">
            A new verification email has been sent. After your email has been successfully verified, please reload the
            page or sign in again, and go to the dashboard.
          </Alert>
        )}
        <Box>
          <Typography paragraph>
            A verification email has been sent to your registered email address. Please follow the instruction in that
            email to verify your account.
          </Typography>
          <Typography paragraph>
            After your email has been successfully verified, please reload the page or sign in again, and go to the
            dashboard.
          </Typography>
          <Typography paragraph>You can also click the button below to send the verification email again.</Typography>
          <Box sx={{ textAlign: 'center' }}>
            <WaitButton onClick={handleVerify} wait={state.status === 'pending'} variant="contained">
              Verify my Email
            </WaitButton>
          </Box>

          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <Typography marginBottom={2}>
              Go to the <Link href="/dashboard">Dashboard</Link>
            </Typography>
            <Typography marginBottom={4}>
              Go back to the <Link href="/">Home page</Link>
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
